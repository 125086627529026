@import url('https://fonts.googleapis.com/css2?family=Anton&family=Hind+Siliguri:wght@300;400;700&family=Poppins:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

/*
Font-Family designations to assign fonts
font-family: 'Hind Siliguri', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Anton', sans-serif;
*/


/*Sitewide styles - Headers, Footers, Site Menus*/
/*Overall document styles*/
:root{
  --gradient:linear-gradient(115deg,#06f,#15197e);
  --menu-hover-gradient:90deg, transparent,#fff 25% 75%,transparent;
  --soc-med-hover-gradient:90deg, transparent, #15197e 25% 75%, transparent;
  --insta-gradient:radial-gradient(at bottom left, #f6cd72 20%,#fd7e28 30%,#dd2d7f 40%,#a431b9);
  --transition:all .25s ease-in-out;
}
html, body{
    height:100%;
    font-family:'Poppins', sans-serif;
    margin:0;
    cursor: default;
  }

  .main-wrapper{
    min-height:100vh;
    display:flex;
    flex-direction:column;
  }
  main{
    flex:1; /*forces the footer to at least the bottom of the page*/
  }
  p{
    text-align:left;
  }

/*page titles*/
.page-title{
  background:var(--gradient);
  color:transparent;
  background-clip:text;
  -webkit-background-clip:text;
  -moz-background-clip: text;
}


/*Header and Full Sized Menu*/
header{
    width:100%;
    background:var(--gradient);
    color:#fff;
  }
  .full-header-container{
    display:flex;
  }
  .logo-container{
    padding:10px;
    flex-basis:25%;
    flex-shrink:0;
    text-align:center;
  }
  .site-logo{
    width:100%;
  }
  .full-nav-container{
    flex-basis:75%;
    padding-left:50px;
  }
  .full-site-nav{
    display:flex;
    width:100%;
    height:100%;
    font-family:'Anton',sans-serif;
  }
  .full-nav-link{
    display:flex;
    flex-direction:column;
    text-align:center;
    justify-content:center;
  }
  .menu-link{
    text-transform:uppercase;
    letter-spacing: 2px;
    color:inherit;
    text-decoration:none;
    font-size:22px;
    font-weight:lighter;
    padding:5px 10px;
    position:relative;
    z-index:4;
    white-space: nowrap;
    transition:var(--transition);
  }
  .menu-link::before{
    position:absolute;
    top:0;
    left:0;
    right:0;
    margin:auto;
    width:0%;
    height:1px;
    background:linear-gradient(var(--menu-hover-gradient));
    content:" ";
    transition:var(--transition);
  }
  .menu-link::after{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    width:0%;
    height:1px;
    background:linear-gradient(var(--menu-hover-gradient));
    content:" ";
    transition:var(--transition);
  }
  .menu-link:hover::after, .menu-link:hover::before{
    width:100%;
  }


/*Mobile Header and Menu*/
.mobile-header-container{
    width:100%;
    display:none;
  }
.mobile-header-space{
    flex-basis:65%;
  }
  .mobile-menu-container{
    flex-basis:10%;
    flex-shrink:0;
    margin-right:15px;
    text-align:right;
  }
  .menu-trigger{
    padding-top:8px;
    font-size:36px;
  }
  .dropdown-menu{
    list-style:none;
    position:absolute;
    padding:0px;
    height:0px;
    width:0px;
    overflow:hidden;
    top:40px;
    right:0px;
    background:radial-gradient(at top left,#1c2085, #15197e);
    border-radius:4px;
    transition:all .3s ease-in-out;
    white-space: nowrap;
  }
  .menu-item{
    padding:5px 20px;
    text-align:left;
    text-indent:none;
    transition:var(--transition);
    position:relative;
  }
  .menu-item::after{
    position:absolute;
    bottom:4px;
    left:0;
    right:0;
    margin:auto;
    width:0%;
    height:1px;
    background:linear-gradient(90deg, transparent,#15197e 25% 75%,transparent);
    content:" ";
    transition:var(--transition);
  }
  .menu-item:hover{
    background:#fff;
    color:#15197e;
  }
  .menu-item:hover::after{
    width:75%;
  }
.dropdown-menu-icon{
    padding-right:5px;
  }
  .mobile-menu-link{
    text-decoration:none;
    color:inherit;
    font-size:20px; 
  }
  .menu-trigger:hover{
    color:#ff1616;
  }
  .menu-trigger:hover+.dropdown-menu{
    height:240px;/* Will need to be 280 once the Social Media page is back in */
    width:175px;
  }
  .dropdown-menu:hover{
    height:240px;/* Will need to be 280 once the Social Media page is back in */
    width:175px;
  }


/*Sitewide Main Content Containers*/
.main-content-container{
    font-size:18px;
    width:90%;
    padding:25px;
    margin:auto;
    text-align:center;
  }
.page-content-container{
  width:90%;
  margin:auto;
    padding:20px;
}


/*Footer Styles*/
footer{
  width:100%;
    background:#15197e;
    color:#fff;
    padding:10px 0px;
  }
  .foot-container{
    display:flex;
    text-align:center;
    margin:0 15%;
  }
  .social-media-container,.copyright-container{
    flex-basis:50%;
    flex-shrink:0;
  }
  .copyright-container{
    display:flex;
    flex-direction:column;
    justify-content:center;
    font-size:.8em;
  }
  .register{
    color:#1da1f2;
  }

/*Social Media Icons*/
.icon{
    display:inline-grid;
    align-content:center;
    justify-content:center;
    font-size:16px;
    width:24px;
    height:24px;
    border:2px solid white;
    border-radius:50%;
    margin:10px;
    color:inherit;
    transition:var(--transition);
  }
  .fa,.fab{
    margin:4px;
  }
/*facebook*/
.facebook-icon:hover{
    border:2px solid #06f;
    color:#06f;
  }
  
  /*instagram*/
  .instagram-icon:hover{
    border:2px solid #ed4956;
    color:#ed4956;
  }
  
  /*twitter*/
  .twitter-icon:hover{
    border:2px solid #1da1f2;
    color:#1da1f2;
  }
  
  /*Messenger*/
  .messenger-icon:hover{
    border:2px solid #06f;
    color:#06f;
  }






/*Home Page Styles*/
/*Containers*/

/*Images*/
.intro-photo{
  max-width:90%;
  max-height:500px;
  box-shadow:3px 3px 5px rgba(0,0,0,.6);
  margin:15px auto;
}
/*Text*/
.intro-box{
  margin:0;
}
.intro,.intro-box>p{
  text-align:left;
  text-align-last:left;
  text-indent:2em;
}
.intro::first-letter{
  font-family:"Times New Roman", serif;
  font-size:1.8em;
}
.intro-list{
  text-align:justify;
  list-style-type:disc;
}
.intro-list>li{
  margin:10px;
  text-indent:0;
}
/*Links*/





/*About Page Styles*/
/* Containers */
.topics-container{
    width:90%;
    margin:auto;
    padding:15px;
}
.position-container{
    padding-left:25px;
    padding-right:25px;
    overflow:hidden;
    transition:var(--transition);
}
.position-container>.intro{
    transition:var(--transition);
}
/* Tabs - page specific styles here, general styles are listed below in the Social Media section under .tab  */
.about-tab:hover{
  color:#1da1f2;
}
.tab-label{
  margin-left: 10px;
}
/* About */
.bio-photo{
  max-height:500px;
  max-width:70vw;
  margin:auto;
}
.bio{
  text-align:justify;
}
    /* Photo gallery in About Me section */
.gallery{
  margin:auto;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.photo-card{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  max-width:90%;
  height:300px;
  margin:15px auto;
  border-radius:6px;
  overflow:hidden;
  transition:var(--transition);
}
.photo-card:hover{
  box-shadow:5px 5px 5px rgba(0,0,0,.6);
}
.gallery-img{
  max-width:110%;
  max-height:110%;
}


/* Topics & Positions */
.topic-heading{
    text-align:center;
    transition:var(--transition);
}
.topic-arrow{
    color:#1c2085;
    padding:10px;
    transition:all .2s linear;
}

.topic-row{

  display:inline-block;
  max-width:80%;
  overflow:auto;
  border:1px solid #1c208595;
  margin:10px;
  transition:all .25s ease-in-out;
}

.topic-row:hover .topic-heading{
    margin-left:5%;
}



/*Social Media Page Styles*/
/*Tabs*/
.tab{
  cursor:pointer;
  color:#1c2085;
  margin:0;
  padding:5px 20px;
  border:none;
  background:inherit;
  font-size:20px;
  transition:var(--transition);
  position:relative;
}
#insta-tab, #twitter-tab, #fb-tab{
  transition:var(--transition);
}
.tab::before{
  position:absolute;
  top:0;
  left:0;
  right:0;
  margin:auto;
  width:0%;
  height:1px;
  background:linear-gradient(var(--soc-med-hover-gradient));
  content:" ";
  transition:var(--transition);
}
.tab::after{
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  width:0%;
  height:1px;
  background:linear-gradient(var(--soc-med-hover-gradient));
  content:" ";
  transition:var(--transition);
}
.tab:hover::after, .tab:hover::before{
  width:100%;
}
#insta-tab:hover, #insta-tab button.active{
  /*color:transparent;*/
  background:var(--insta-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#insta-tab:hover >.tab-icon{
  color:#fd7e28;
}
#twitter-tab:hover, #twitter-tab button.active{
  color:#1da1f2;
}
#fb-tab:hover, #fb-tab button.active{
  color:#06f;
}
.tab button.active{
  background:#acf;
}
/*Tab contents*/
.tabs-content-container{
  min-height:200px;
  min-width:90%;
}
.tab-content{
    margin-top:30px;
}
.postbox{
  display:flex;
  min-height:300px;
  width:100%;
  justify-content: space-around;
}
.post-container{
  flex:1;
  display:flex;
  flex-direction:column;
  justify-content:center;
  padding:20px;
  margin:10px;
}
.post{
  width:98%;
  margin:auto;
  max-height:500px;
  border:1px solid #aaa;
  border-radius:20px;
  padding:10px;
  box-shadow:5px 5px 10px rgba(0,0,0,.6);
}
.flexrow{
  display:flex;
}
.head{
  align-items:flex-end;
}
.bod{
  margin:10px auto;
}
.foot{
  justify-content:flex-end;
}
.profile-pic{
  height:50px;
  width:50px;
  border-radius:50%;
  margin-left:5px;
  margin-right:30px;
  overflow:hidden;
  background-position:center;
}
.profile-pic>img{
  width:50px;
  height:50px;
}
.username,.timestamp{
  color:#888;
  margin-left:5px;
}
.post-img{
  width:90%;
  height:300px;
  background:url('https://picsum.photos/400');
  background-size:cover;
  background-position:center;
  margin:15px auto;
  border-radius:20px;
  box-shadow:2px 2px 10px rgba(0,0,0,.6);
}
.post-text{
  font-size:.8em;
}
.fb-text{
  max-height:12em;
  position:relative;
  overflow:scroll;
  scrollbar-width:none;
}
.fb-text::-webkit-scrollbar{
  display:none;
}
.fb-text::before{
  position:absolute;
  right:0;
  top:0;
  height:.8em;
  width:50%;
  background:linear-gradient(90deg, transparent,#fff);
}
/*Links to Social Media Pages*/
.soc-med-link{
  color:#06f;
  text-decoration:none;
}
.soc-med-link:hover{
  color:#1da1f2;
  text-decoration:underline;
}





/*Calendar Page Styles*/


/*Containers*/
.cal-container-plus{
	display:flex;
	width:90vw;
	margin:auto;
	max-width:900px;
}
.cal-btn-container{
  display:flex;
  justify-content: center;
	padding:20px 40px;
	text-align:center;
	flex-basis:50%;
}
/*Calendar*/
.full-cal,.mobile-cal{
  border:2px solid #1c2085;
  border-radius:4px;
}
.mobile-cal{
  display:none;
}
/*Buttons are styled in the Social Media page above as tabs with specific effects below*/
.cal-btn{
  width:75px;
  height:30px;
  margin:20px auto;
  float:left;
}
.cal-btn:hover{
  color:#1da1f2;
}
.cal-btn-info{
  margin:20px;
}
/* Misc. */
.cal-btn-info>.fa{
  color:#1c2085;
}
.cal-arrow{
  transform:rotate(45deg);
}




/*Donation Page Styles*/
/* Container */
.donation-container{
  display:flex;
}
.donation-info-container,.donate-container{
  flex-basis:50%;
  flex-grow:0;
}
.donate-container{
  float:right;
  display:flex;
  flex-direction:column;
  justify-content:center;
}
/* Donation Link */
.donate-link-container{
  width:75%;
  margin:auto;
  height:150px;
  border:4px solid #06f;
  border-radius:4px;
  background:var(--gradient);
  color:#fff;
  display:flex;
  flex-direction:column;
  justify-content:center;
}
.donation-link{
  display:inline-block;
  margin:auto;
  color:#fff;
  padding:10px 15px;
  text-decoration:none;
  transition:all .3s ease-in-out;
}
.donation-link:hover{
  color:#1da1f2;
}
/* Images */
.donate-img{
  max-height:100%;
  max-width:130%;
}

/**/
/**/





/* Vote Page Styles */
/* Containers */
.vote-subheading{
  display:flex;
  width:50%;
  margin:auto;
}
.subsub{
  flex:1;
}
.content-container{
  text-align:left;
  width:90%;
  margin:auto;
}
.sub-container{
  display:flex;
}
.countdown-container{
  flex-basis:50%;
  margin-top:30px;
  text-align:center;
}
.vote-list-container{
  flex-basis:50%;
  display:flex;
  align-content:center;
}
.list-box{
  display:inline;
  margin:auto;
}
/*List Styles*/
.vote-links{
  list-style-type:square;
  list-style-position:inside;
}
.vote-links>li{
  margin-bottom:10px;
}
.vote-link,.donate-link{
  color:#06f;
  text-decoration:none;
}
.vote-link:hover,.donate-link:hover{
  color:#1da1f2;
  text-decoration:underline;
}

/*Countdown Timer*/
.countdown{
  position:relative;
  text-align:center;
  width:100%;
  height:100%;
  display:grid;
  align-content:center;
}
.countdown>iframe{
  margin:auto;
}
/**/
/**/






/*Contact Page Styles*/
/*Containers*/
.contact-container{
  display:flex;
  width:80%;
  margin:auto;
}
.alt-contact-container{
  text-align:left;
  max-width:90%;
}
.alt-contact-container,.contact-form-container{
  flex:1;
}
/*Form*/
.form-row{
	display:flex;
}
.contact-input,.contact-textarea{
	flex:1;
	margin:20px 5px;
	padding:5px 8px;
	background:transparent;
	border:none;
	border-bottom:1px solid #15197e59;
  font-family:inherit;
  color:#15197e;
}
.contact-textarea{
  height:10em;
  resize:none;
}
.contact-input:hover, .contact-textarea:hover, .contact-input:focus, .contact-textarea:focus{
	background:var(--gradient);
	outline:none;
	color:white;
	border-radius:4px;
}
::placeholder { /* Blue place holder text when necessary in Chrome, Firefox, Opera, Safari 10.1+ */
  color: #15197e;
  opacity: 1; /* Firefox has a default opacity for placeholder text less than 1 so this corrects it */
}
:-ms-input-placeholder { /* for Internet Explorer 10-11 */
  color: #15197e;
}
::-ms-input-placeholder { /* for Microsoft Edge */
  color: #15197e;
}
:hover::placeholder, :focus::placeholder {
	color:white;
}
:hover:-ms-input-placeholder, :focus:-ms-input-placeholder {
	color:white;
}
:hover::-ms-input-placeholder, :focus::-ms-input-placeholder{
	color:white;
}
/*Buttons are styled in the Social Media page above as tabs with form-specific properties below*/
.form-btn{
  font-size:.9em;
  margin-top:25px;
}
.form-btn:hover{
  color:#1da1f2;
}
/* Alternate Contact Information*/
.alt-contact{
  margin:20px auto 20px 15%;
}
.contact-disclaimer{
  margin-top:30px;
  font-size:.7em;
  width:60%;
}
/* Form Thank You */
#form-thank-you{
  display:none;
  width:400px;
  padding:16px;
  border:2px solid #0fbd00;
  border-radius:4px;
  color:#fff;
  background:#1c2085;
  position:relative;
  top:-50px;
  left:5%;
}
#form-thank-you>.thank-you>.far{
  color:#0fbd00;
}


/*Media Queries for Responsiveness*/
@media screen and (min-width:1501px){
  .menu-link{
    font-size:32px;
  }
  .gallery{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (max-width:1600px){
  .gallery{
    grid-template-columns:1fr 1fr 1fr;
  }
}
@media screen and (max-width:1240px){
  .full-nav-container{
    padding-left:10px;
  }
  .menu-link{
    padding:5px 10px;
  }
  .sub-container{
    flex-direction:column;
  }
  .gallery{
    grid-template-columns: 1fr 1fr;
  }
  .topic-row{
    max-width:90%;
  }
}
@media screen and (max-width:1059px){
    .full-header-container{
      display:none;
    }
    .mobile-header-container{
      display:flex;
    }
    .logo-container{
      flex-basis:30%;
      flex-shrink:0;
    }
    .foot-container{
      flex-direction:column;
    }
    .home-intro-container{
      display:flex;
      flex-direction:column;
    }
  .intro-photo{
    float:none;
    margin:auto;
  }
  .contact-container{
    flex-direction:column;
  }
  .cal-btn-info{
    text-align:center;
  }
  .topic-row{
    width:100%;
  }
  .postbox{
    flex-direction:column;
  }
  }

@media screen and (max-width:899px){
  .mobile-cal{
    display:initial;
  }
  .full-cal{
    display:none;
  }
  .donation-container{
    flex-direction:column;
  }
}

@media screen and (max-width:767px){
  /*.main-content-container{
    width:98%;
  }*/
  p,ul,li{
    font-size:14px;
  }
  .page-title,.tab-label{
    font-size:1.2em;
  }
  .logo-container{
    flex-basis: 45%;
  }
  .tab-label{
    font-size:.8em;
  }
  .form-row{
		flex-direction:column;
  }
  .alt-contact{
    margin:20px auto;
  }
	.cal-container-plus{
		flex-direction:column-reverse;
  }
  .vote-subheading{
    width:90%;
  }
  .gallery{
    grid-template-columns: 1fr;
  }
}
.see-more {
    margin:30px auto;
}
@media screen and (max-width:464px){
  .vote-subheading{
    flex-direction: column;
  }
}
@media screen and (max-width:323px){
  p,ul,li{
    font-size:12px;
  }
}